import Constants from '../../config.local.js'

var StatistiqueMixin = {

    data() {
        return {

        }
    },

	methods: {
        /* Retourne licencekey, synchro_inserted et username des utilisateurs qui ont leur dernière date de synchronisation entre les deux dates passées en paramètre */
        getLastSynchroBetweenDates: async function(begin_date, end_date) {
            const url = Constants.STATISTIQUE_LAST_SYNCHRO + "?debut=" +  begin_date.toDateInputValue() + "&fin=" + end_date.toDateInputValue() + "&licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getLastSynchroBetweenDates", url)
            .catch(error => {
                console.log("StatistiqueMixin::getLastSynchroBetweenDates => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getNbLastSynchroBetweenDates: async function(begin_date, end_date) {
            const url = Constants.STATISTIQUE_NB_LAST_SYNCHRO + "?debut=" +  begin_date.toDateInputValue() + "&fin=" + end_date.toDateInputValue() + "&licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getNbLastSynchroBetweenDates", url)
            .catch(error => {
                console.log("StatistiqueMixin::getNbLastSynchroBetweenDates => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getDevices: async function() {
            const url = Constants.STATISTIQUE_DEVICE + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getDevices", url )
            .catch(error => {
                console.log("StatistiqueMixin::getDevices => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getNbUser: async function() {
            const url = Constants.STATISTIQUE_NB_USER + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getNbUser", url)
            .catch(error => {
                console.log("StatistiqueMixin::getNbUser => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getNewUserBetweenDates: async function(begin_date, end_date) {
            const url = Constants.STATISTIQUE_NEW_USER + "?debut=" +  begin_date.toDateInputValue() + "&fin=" + end_date.toDateInputValue() + "&licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getNewUserBetweenDates", url)
            .catch(error => {
                console.log("StatistiqueMixin::getNewUserBetweenDates => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getNbLicence: async function() {
            const url = Constants.STATISTIQUE_NB_LICENCE + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getNbLicence", url)
            .catch(error => {
                console.log("StatistiqueMixin::getNbLicence => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getNbHorse: async function() {
            const url = Constants.STATISTIQUE_NB_HORSE + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getNbHorse", url)
            .catch(error => {
                console.log("StatistiqueMixin::getNbHorse => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getLicenceNbHorseMinMax: async function(nb_horse_min, nb_horse_max) {
            const url = Constants.STATISTIQUE_LICENCE_NB_HORSE_LIMIT + "?min=" +  nb_horse_min + "&max=" + nb_horse_max + "&licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getLicenceNbHorseMinMax", url)
            .catch(error => {
                console.log("StatistiqueMixin::getLicenceNbHorseMinMax => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getLastSynchro: async function(licence_key) {
            const params = { 'licence_key': licence_key }
            const url = this.constructRoute(Constants.STATISTIQUE_USER_LAST_SYNCHRO, params)
            const result = await this.$request.request_get_api("Statistique:getLastSynchro", url + "?licence_key=" + Constants.USER_LICENCE_KEY)
            .catch(error => {
                console.log("StatistiqueMixin::getLastSynchro => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getUserDevice: async function(licence_key) {
            const params = { 'licence_key': licence_key }
            const url = this.constructRoute(Constants.STATISTIQUE_USER_DEVICE, params)
            const result = await this.$request.request_get_api("Statistique:getUserDevice", url + "?licence_key=" + Constants.USER_LICENCE_KEY)
            .catch(error => {
                console.log("StatistiqueMixin::getUserDevice => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getLicenceNbHorse: async function(licence_key) {
            const params = { 'licence_key': licence_key }
            const url = this.constructRoute(Constants.STATISTIQUE_LICENCE_NB_HORSE, params)
            const result = await this.$request.request_get_api("Statistique:getLicenceNbHorse", url + "?licence_key=" + Constants.USER_LICENCE_KEY)
            .catch(error => {
                console.log("StatistiqueMixin::getLicenceNbHorse => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getNewAcount: async function() {
            const url = Constants.STATISTIQUE_NEW_ACCOUNT + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getNewAcount", url)
            .catch(error => {
                console.log("StatistiqueMixin::getNewAcount => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getParrainge: async function() {
           const url = Constants.STATISTIQUES_PARRAINAGE + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("Statistique:getParrainge", url)
            .catch(error => {
                console.log("StatistiqueMixin::getParrainge => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        }
	}
}

export default StatistiqueMixin
