<template>
    <div class="table-responsive" v-if="items && columns">
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        >
        </b-pagination>

        <b-table
        ref="table_main"
        striped hover selectable
        :items="items"
        :fields="columns"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="onglet_user"
        >

        <!-- <template :slot="click_field" slot-scope="row">
            <p v-on:click="onglet_user()">{{row.item.licence_username}}</p>
        </template> -->

        </b-table>
    </div>
</template>

<script type="text/javascript">
import User from '@/mixins/User.js'

export default {
    name: "TableStats",
    mixins: [User],
    props: [
        'items',
        'columns',
        'click_field',
        'click_event'
    ],
    data() {
        return {
            currentPage: 1,
            perPage: 20,
            selectMode: 'multi',
        }
    },
    methods: {
        onglet_user(item) {
            this.click_event(item)
        },
    },
    computed: {
        rows() {
            return this.items.length
        },
    },
}
</script>
