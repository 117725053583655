<template>
    <div class="w-100">
        <form @submit.prevent="validForm" class="row">
            <div class="col-5">
                <input type="number" class="form-control" v-model="number_min" placeholder="Nombre de chevaux minimum" required>
            </div>
            <div class="col-5">
                <input type="number" class="form-control" v-model="number_max" placeholder="Nombre de chevaux maximum" required>
            </div>
            <div class="col-2 text-center">
                <button class="btn btn-primary">
                    <div class="d-inline">
                        Rechercher
                    </div>
                    <div class="d-none">
                        <font-awesome-icon class="spinner_home" :icon='["far", "spinner"]' spin />
                    </div>
                </button>
            </div>
        </form>
        <div v-if="itemsLicence" class="row">
            <div class="col-12">
                <div class="box mt-3">
                    <TableStats :items="itemsLicence" :columns="columnsUsers" click_field="licence_username" :click_event="onClickRow" />
                </div>
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
import { EventBus } from 'EventBus';
import StatistiquesMixin from "@/mixins/Statistiques.js";
import TableStats from "@/components/TableStats.vue";

export default {
    name: "LastSynchro",
    mixins: [StatistiquesMixin],
    data () {
        return {
            number_min: '',
            number_max: '',
            itemsLicence: null,
            columnsUsers : [''],
        }
    },
    methods: {
        async validForm(e) {
            e.target[2].childNodes[0].className = "d-none"
            e.target[2].childNodes[1].className = "d-inline"

           const licences = await this.getLicenceNbHorseMinMax(this.number_min, this.number_max)
            .catch(e => {
                console.log("Erreur sur getLicenceNbHorseMinMax", e)
            })
            this.itemsLicence = licences

            e.target[2].childNodes[0].className = "d-inline"
            e.target[2].childNodes[1].className = "d-none"

        },
        onClickRow(item) {
            // Au click on swith sur l'onglet user
            EventBus.$emit('Statistiques::changeTabFromName', 'user')

            // On envoie la licencekey au composant user
            EventBus.$emit('UserStats::autoCompleteInput', item.horse_licencekey)
        }
    },
    components: {
        TableStats
    }
}
</script>
